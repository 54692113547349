@media print {
  [data-testid="Authorized"] {
    display: none !important;
  }

  [data-testid="nav-links"] {
    display: none !important;
  }

  [data-testid="burger-menu"] {
    display: none !important;
  }

  [data-testid="sticky-nav"] {
    display: none !important;
  }
}

[data-testid="navigation-scroll-sidebar"] {
  -ms-overflow-style: none;
}

[data-testid="navigation-scroll-sidebar"]::-webkit-scrollbar {
  display: none;
}